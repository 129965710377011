import { NgClass, NgOptimizedImage, NgStyle, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { get, isString } from 'lodash-es';

import { hashWithSha256 } from '@@core/helpers/core.helper';

@Component({
	selector: 'sl-avatar',
	templateUrl: './avatar.component.html',
	styleUrl: './avatar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		UpperCasePipe,
		NgOptimizedImage,
		NgClass,
		NgStyle
	],
})
export class AvatarComponent implements OnChanges {
	readonly clickableSignal$ = input<boolean>(false, { alias: 'clickable' });
	readonly valueSignal$ = input<any>(null, { alias: 'value' });
	readonly rawTextSignal$ = input<string>(null, { alias: 'rawText' });
	readonly sizeSignal$ = input<number>(30, { alias: 'size' });
	readonly propToHashSignal$ = input<string>(null, { alias: 'propToHash' });
	readonly propToDisplaySignal$ = input<string>(null, { alias: 'propToDisplay' });
	readonly styleClassSignal$ = input<string>(null, { alias: 'styleClass' });

	readonly initialsSignal$ = signal<string>('');
	readonly bucketIndexSignal$ = signal(0);
	readonly computedAvatarClassesSignal$ = computed(() => {
		const classObj = {
			'avatar-container': true,
			'clickable': this.clickableSignal$(),
			[`avatar-bucket-${this.bucketIndexSignal$()}`]: true,
		};

		if (this.styleClassSignal$()) {
			classObj[this.styleClassSignal$()] = true;
		}

		return classObj;
	});

	readonly computedAvatarStyleSignal$ = computed(() => (
		{
			'width.px': this.sizeSignal$(),
			'height.px': this.sizeSignal$(),
		}
	));

	readonly #numberOfBuckets = 20;

	readonly #cdRef = inject(ChangeDetectorRef);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.valueSignal$ && this.valueSignal$()) {
			this.#setInitials();

			const valueToHash = this.#getValueToHash();
			void this.#hashWithSha256AndSetBucketIndex(valueToHash);
		}
	}

	#setInitials(): void {
		if (isString(this.valueSignal$())) {
			this.initialsSignal$.set((this.valueSignal$() as string).split(' ').map(name => name[0]).join('') || 'UN');
		} else {
			if (this.propToDisplaySignal$()) {
				this.initialsSignal$.set((get(this.valueSignal$(), this.propToDisplaySignal$()) as string)?.split(' ').map(name => name[0]).join('') || 'UN');
			}
		}
	}

	#getValueToHash(): string {
		let propHashValue = null;

		if (this.propToHashSignal$()) {
			propHashValue = get(this.valueSignal$(), this.propToHashSignal$()) as string || 'unknown';
		}

		return (propHashValue || this.valueSignal$()) as string;
	}

	async #hashWithSha256AndSetBucketIndex(valueToHash: string): Promise<void> {
		try {
			const hash = await hashWithSha256(valueToHash);
			const hashInt = parseInt(hash, 16);
			this.bucketIndexSignal$.set(hashInt % this.#numberOfBuckets);
			this.#cdRef.detectChanges();
		} catch (error) {
			console.error('Hashing failed', error);
		}
	}
}
